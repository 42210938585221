






































import { defineComponent } from "@vue/composition-api";
import { Button, CellGroup, Field, Form, Picker, Popup, Radio, RadioGroup } from "vant";
import axios from "@/helpers/axios";

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Form.name]: Form,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button
  },
  data() {
    return {
      title: "个人",
      userType: 1,
      type: 1,
      socialCreditNumber: "",
      bankName: "",
      bankAccount: "",
      businessAddress: "",
      businessPhone: "",

      typePicker: {
        show: false,
        columns: [
          // { text: "增值税普通发票", type: 1 },
          { text: "企业增值税普通发票", type: 3 },
          { text: "增值税专用发票", type: 4 }
        ],
        allColumns: [
          { text: "增值税普通发票", type: 1 },
          { text: "企业增值税普通发票", type: 3 },
          { text: "增值税专用发票", type: 4 }
        ]
      }
    };
  },
  computed: {
    typeMsg(): string {
      return this.typePicker.allColumns.find(t => t.type == this.type)?.text || "";
    }
  },
  created() {
    this.loadInfo();
  },
  methods: {
    setUserType(userType: number) {
      if (userType === 1) {
        this.type = 1;
        this.title = "个人";
        this.socialCreditNumber = "";
        this.bankName = "";
        this.bankAccount = "";
        this.businessAddress = "";
        this.businessPhone = "";
      } else if (userType == 2) {
        this.type = 3;
      }
    },
    async loadInfo() {
      const res = await axios
        .post("/api/manage/invoice/info")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!res) return;
      if (!res.hasInfo) return;
      const info = res.info;
      this.title = info.title;
      this.type = info.type;
      this.userType = info.type >= 3 ? 2 : 1;
      this.socialCreditNumber = info.socialCreditNumber;
      this.bankName = info.bankName;
      this.bankAccount = info.bankAccount;
      this.businessAddress = info.businessAddress;
      this.businessPhone = info.businessPhone;
    },
    async saveInfo() {
      const res = await axios
        .post("/api/manage/invoice/saveInfo", {
          title: this.title,
          type: this.type,
          socialCreditNumber: this.socialCreditNumber,
          bankName: this.bankName,
          bankAccount: this.bankAccount,
          businessAddress: this.businessAddress,
          businessPhone: this.businessPhone
        })
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      this.loadInfo();
    }
  }
});
